<template>
  <div>
    <router-view v-show='$route.matched.length==3'></router-view>
    <PageHeaderLayout v-show='$route.matched.length==2'>
      <div class='main-page-content'>
        <el-row :gutter='15'>
          <el-col :lg='2' :md='4'>
            <el-link @click="goToPage('/setting/site')">站点配置</el-link>
          </el-col>
          <el-col :lg='2' :md='4'>
            <el-link @click="goToPage('/setting/artist_map')">红人对照</el-link>
          </el-col>
          <el-col :lg='2' :md='4'>
            <el-link @click="goToPage('/setting/notify_setting')">站内消息模板</el-link>
          </el-col>
          <el-col :lg='2' :md='4'>
            <el-link @click="goToPage('/setting/notify_dingtalk')">钉钉消息</el-link>
          </el-col>
        </el-row>
        <el-divider content-position='left'>模块</el-divider>
        <div>
          <h2>系统地图</h2>
          <div>
            <el-table :data='navMenus'  default-expand-all
                      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"  row-key="display_name" border>
              <el-table-column type='index' label='序号' align='center'></el-table-column>
              <el-table-column prop='display_name' label='模块名称' header-align='center' align='left' width='400'></el-table-column>
              <el-table-column prop='release_date' label='上线时间' align='center' width='140'></el-table-column>
              <el-table-column prop='is_open' label='是否开通' align='center' width='120'>
                <template slot-scope='scope'>
                  <el-switch
                    v-model='scope.row.is_open'
                    active-value='Y'
                    inactive-value='N'
                  ></el-switch>
                </template>
              </el-table-column>
              <el-table-column prop='remark' label='备注' header-align='center' align='left'></el-table-column>
            </el-table>
          </div>
        </div>


      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import { mapGetters } from 'vuex'
import menus from '@/json/menus.json'
export default {
  name: 'SettingBase',
  components: {
    PageHeaderLayout
  },
  data() {
    return {
      navMenus: menus,
      dataList: [
        { display_name: '用户管理', release_date: '', is_open: 'Y' },
        { display_name: '部门管理', release_date: '', is_open: 'Y' },
        { display_name: '岗位管理', release_date: '', is_open: 'Y' },
        { display_name: '系统部门', release_date: '', is_open: 'Y' },
        { display_name: '菜单管理', release_date: '', is_open: 'Y' },
        { display_name: '权限管理', release_date: '', is_open: 'Y' },
        { display_name: '角色管理', release_date: '', is_open: 'Y' },
        { display_name: '系统公告', release_date: '', is_open: 'Y' },
        { display_name: '附件管理', release_date: '', is_open: 'Y' },
        { display_name: '红人管理', release_date: '', is_open: 'Y' },
        { display_name: '涨粉数据', release_date: '', is_open: 'Y' },
        { display_name: '营收数据', release_date: '', is_open: 'Y' },
        { display_name: 'MCN绩效', release_date: '', is_open: 'Y' },
        { display_name: '签约管理', release_date: '', is_open: 'Y' },
        { display_name: '电商数据', release_date: '', is_open: 'Y' },
        { display_name: '档期管理', release_date: '', is_open: 'Y' },
        { display_name: '排期选品', release_date: '', is_open: 'Y'
        ,children:[
            {display_name: '直播排期', release_date: '', is_open: 'Y'},
            {display_name: '产品库', release_date: '', is_open: 'Y'},
            {display_name: '产品库审核（招商小程序）', release_date: '', is_open: 'Y'},
            {display_name: '招商情况一览', release_date: '', is_open: 'Y'},
            {display_name: '品牌库', release_date: '', is_open: 'Y',remark:'试用反馈处理中'},
          ]},
        { display_name: '仪表盘', release_date: '', is_open: 'Y' },
        { display_name: '刊例管理', release_date: '', is_open: 'Y' },
        { display_name: '红人分成', release_date: '', is_open: 'Y' },
        { display_name: '询单管理', release_date: '', is_open: 'Y' },
        { display_name: 'OA审批', release_date: '', is_open: 'Y' },
        { display_name: '红人资料库', release_date: '', is_open: 'Y' },
        { display_name: '项目结款', release_date: '', is_open: 'Y' },
        { display_name: '红人数据模型', release_date: '', is_open: 'Y' },
      ]
    }
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  watch: {},
  methods: {
    goToPage(routeName) {
      this.$router.push({ path: routeName })
    }
  },

  mounted() {
  }
}
</script>

<style lang='stylus'>
</style>
